import { Injectable } from '@angular/core';
import { HttpBaseService } from '@app/shared/services/httpbase.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private httpBaseService: HttpBaseService) { }
  public getAuditList(payload): Observable<any> {
    const apiUrl = 'Audit/GetAuditListUsingServerSidePagination';
    return this.httpBaseService.post(apiUrl, payload);
  }

  getAuditConsoldiatedDetailsById(auditId, userId): Observable<any> {
    const apiUrl = 'Audit/GetAuditConsoldiatedDetailsById/' + auditId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  getAuditById(auditId, userId): Observable<any> {
    const apiUrl = 'Audit/GetAuditById/' + auditId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public createAuditSalesTeam(requestData): Observable<any> {
    const apiUrl = 'AuditSalesTeam/CreateAuditSalesTeam';
    return this.httpBaseService.post(apiUrl, requestData);
  }

  public deleteAuditSalesTeam(requestData): Observable<any> {
    const apiUrl = 'AuditSalesTeam/DeleteAuditSalesTeam';
    return this.httpBaseService.post(apiUrl, requestData);
  }

  public getAuditSalesTeamByAuditId(auditId, userId): Observable<any> {
    const apiUrl = 'AuditSalesTeam/GetAuditSalesTeamByAuditId/' + auditId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getCoveredStandardsByAuditId(auditId, userId): Observable<any> {
    const apiUrl = 'Audit/getCoveredStandardsByAuditId/' + auditId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getAuditHoursbyAuditId(auditId, userId): Observable<any> {
    const apiUrl = 'Audit/getAuditHoursbyAuditId/' + auditId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getPreviousAuditList(payload): Observable<any> {
    const apiUrl = 'Audit/GetPreviousAuditListUsingServerSidePagination';
    return this.httpBaseService.post(apiUrl, payload);
  }

  public createAuditConsolidated(requestData): Observable<any> {
    const apiUrl = 'Audit/CreateAuditConsolidated';
    return this.httpBaseService.post(apiUrl, requestData);
  }

  public updateAuditStatus(requestData): Observable<any> {
    const apiUrl = 'Audit/UpdateAuditStatus';
    return this.httpBaseService.post(apiUrl, requestData);
  }

  public addAuditLocation(location): Observable<any> {
    const apiUrl = 'AuditLocation/CreateAuditLocation';
    return this.httpBaseService.post(apiUrl, location);
  }

  public removeAuditLocation(location): Observable<any> {
    const apiUrl = 'AuditLocation/DeleteAuditLocation';
    return this.httpBaseService.post(apiUrl, location);
  }

  public updateAudit(requestData): Observable<any> {
    const apiUrl = 'Audit/UpdateAudit';
    return this.httpBaseService.post(apiUrl, requestData);
  }

  public updateAuditScheduleDate(requestData): Observable<any> {
    const apiUrl = 'Audit/UpdateAuditScheduleDate';
    return this.httpBaseService.post(apiUrl, requestData);
  }

  // public getAuditorSchedulesByAuditorId(auditorId, requestedDate, userId): Observable<any> {
  //   const apiUrl = 'AuditorSchedule/GetAuditorSchedulesByAuditorId/' + auditorId + '/'+ requestedDate + '/' + userId;
  //   return this.httpBaseService.get(apiUrl);
  // }

  public getAuditorSchedulesByAuditorId(auditorId, requestedDate, userId): Observable<any> {
    
    const payload = {
      userId: userId,
      requestedDate: requestedDate,
      auditorIdList: auditorId.toString()
    };

    const apiUrl = 'AuditorSchedule/GetAuditorSchedulesByAuditorIds';
    return this.httpBaseService.post(apiUrl, payload);
  }

  public getAuditorSchedulesByAuditorIds(payload): Observable<any> {
    const apiUrl = 'AuditorSchedule/GetAuditorSchedulesByAuditorIds';
    return this.httpBaseService.post(apiUrl, payload);
  }

  public createAuditorSchedule(payload): Observable<any> {
    const apiUrl = 'AuditorSchedule/CreateAuditorSchedule'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public CreateAuditorScheduleAsCollection(payload): Observable<any> {
    const apiUrl = 'AuditorSchedule/CreateAuditorScheduleAsCollection'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public updateAuditorSchedule(payload): Observable<any> {
    const apiUrl = 'AuditorSchedule/UpdateAuditorSchedule'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public deleteAuditoSchedule(payload): Observable<any> {
    const apiUrl = 'AuditorSchedule/DeleteAuditorSchedule'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public getAuditEventSheetsByAuditId(auditId, featureId, userId): Observable<any> {
    const apiUrl = 'EventSheet/GetEventSheetsByEntityId/' + auditId + '/' + featureId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getAuditProcessFeedbacksByAuditId(auditId, userId): Observable<any> {
    const apiUrl = 'AuditProcessFeedback/GetAuditProcessFeedbacksByAuditId/' + auditId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getAuditTransamittalNotesByAuditId(auditId, userId): Observable<any> {
    const apiUrl = 'AuditTransamittalNotes/GetAuditTransamittalNotesByAuditId/' + auditId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public createAuditTransamittalNotes(payload): Observable<any> {
    const apiUrl = 'AuditTransamittalNotes/CreateAuditTransamittalNotes'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public createAuditEventSheet(payload): Observable<any> {
    const apiUrl = 'EventSheet/CreateEventSheet'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public updateAuditEventSheet(payload): Observable<any> {
    const apiUrl = 'EventSheet/UpdateEventSheet'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public deleteAuditEventSheet(payload): Observable<any> {
    const apiUrl = 'EventSheet/DeleteEventSheet'
    return this.httpBaseService.post(apiUrl, payload);
  }
  public createAuditProcessFeedback(payload): Observable<any> {
    const apiUrl = 'AuditProcessFeedback/CreateAuditProcessFeedback'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public updateAuditProcessFeedback(payload): Observable<any> {
    const apiUrl = 'AuditProcessFeedback/UpdateAuditProcessFeedback'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public deleteAuditProcessFeedback(payload): Observable<any> {
    const apiUrl = 'AuditProcessFeedback/DeleteAuditProcessFeedback'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public getAuditTasksByAuditId(auditId, userId): Observable<any> {
    const apiUrl = 'AuditTask/GetAuditTasksByAuditId/' + auditId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getAuditTaskByTaskId(taskId, userId): Observable<any> {
    const apiUrl = 'AuditTask/GetAuditTaskById/' + taskId + '/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public createAuditTask(payload): Observable<any> {
    const apiUrl = 'AuditTask/CreateAuditTask'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public updateAuditTask(payload): Observable<any> {
    const apiUrl = 'AuditTask/UpdateAuditTask'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public deleteAuditTask(payload): Observable<any> {
    const apiUrl = 'AuditTask/DeleteAuditTask'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public getAuditTaskBaseTypes(userId): Observable<any> {
    const apiUrl = 'AuditTaskBaseType/GetAuditTaskBaseTypes/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getAuditTaskPriorities(userId): Observable<any> {
    const apiUrl = 'AuditTaskPriority/GetAuditTaskPriorities/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getAuditTaskStatuses(userId): Observable<any> {
    const apiUrl = 'AuditTaskStatus/GetAuditTaskStatuses/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getAuditTaskTypes(userId): Observable<any> {
    const apiUrl = 'AuditTaskType/GetAuditTaskTypes/' + userId;
    return this.httpBaseService.get(apiUrl);
  }

  public getAuditListByClientUsingServerSidePagination(payload): Observable<any> {
    const apiUrl = 'Audit/GetAuditListByClientUsingServerSidePagination';
    return this.httpBaseService.post(apiUrl, payload)
  }

  public createAuditAttachment(payload): Observable<any> {
    const apiUrl = 'AuditAttachment/CreateAuditAttachment'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public updateAuditAttachment(payload): Observable<any> {
    const apiUrl = 'AuditAttachment/UpdateAuditAttachment'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public deleteAuditAttachment(payload): Observable<any> {
    const apiUrl = 'AuditAttachment/DeleteAuditAttachment'
    return this.httpBaseService.post(apiUrl, payload);
  }

  public getAuditAttachmentsByAuditId(auditId, userId): Observable<any> {
    const apiUrl = 'AuditAttachment/GetAuditAttachmentsByAuditId/' + auditId + '/'  + userId;
    return this.httpBaseService.get(apiUrl);
  }

}
