import { Injectable } from '@angular/core';
import { HttpBaseService } from '@app/shared/services/httpbase.service';
import { Observable } from 'rxjs';
import { CompanySearchRequest } from '../models/company-search-request';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    constructor(private httpBaseService: HttpBaseService) { }

    public getCompanies(userId, displayAllLocations): Observable<any> {
        const apiUrl = 'Company/GetCompanyList/' + userId + '/' + displayAllLocations;
        return this.httpBaseService.get(apiUrl);
    }

    public getCompanyList(userId): Observable<any> {
        const apiUrl = 'Company/GetCompanies/' + userId;
        return this.httpBaseService.get(apiUrl);
    }


    public getNTSandNQACompanyList(userId): Observable<any> {
        const apiUrl = 'Company/GetNTSandNQACompanyList/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public getCompanyDetails(id, userId): Observable<any> {
        const apiUrl = 'Company/GetCompanyById/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public deleteCompany(deleteCompanyRequest): Observable<any> {
        const apiUrl = 'Company/DeleteCompany';
        return this.httpBaseService.post(apiUrl, deleteCompanyRequest);
    }

    public getSingleCompanyDetail(companyData): Observable<any> {
        const apiUrl = 'POSTGetCompanyDetailsForPassedCompanyId'
        return this.httpBaseService.post(apiUrl, companyData);
    }

    public createCompany(data): Observable<any> {
        const apiUrl = 'Company/CreateCompanyConsolidated'
        return this.httpBaseService.post(apiUrl, data);
    }

    public updateCompany(request): Observable<any> {
        const apiUrl = 'Company/UpdateCompany'
        return this.httpBaseService.post(apiUrl, request);
    }

    public CreateCompanyStat(data): Observable<any> {
        const apiUrl = 'CompanyStat/CreateCompanyStat'
        return this.httpBaseService.post(apiUrl, data);
    }

    public UpdateCompanyStat(request): Observable<any> {
        const apiUrl = 'CompanyStat/UpdateCompanyStat'
        return this.httpBaseService.post(apiUrl, request);
    }

    public createClient(request): Observable<any> {
        const apiUrl = 'Client/CreateClient'
        return this.httpBaseService.post(apiUrl, request);
    }


    public updateClient(request): Observable<any> {
        const apiUrl = 'Client/UpdateClient'
        return this.httpBaseService.post(apiUrl, request);
    }

    public getCompanyStatDetails(id, userId): Observable<any> {
        const apiUrl = 'CompanyStat/GetCompanyStatByCompanyId/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public getClientByCompanyId(id, userId): Observable<any> {
        const apiUrl = 'Client/GetClientByCompanyId/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public GetCompanyCsrsByCompanyId(id, userId): Observable<any> {
        const apiUrl = 'CompanyCsr/GetCompanyCsrsByCompanyId/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public GetCompanyProgramManagersByCompanyId(id, userId): Observable<any> {
        const apiUrl = 'CompanyProgramManager/GetCompanyProgramManagersByCompanyId/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public CreateCompanyCsr(request): Observable<any> {
        const apiUrl = 'CompanyCsr/CreateCompanyCsr';
        return this.httpBaseService.post(apiUrl, request);
    }

    public DeleteCompanyCsr(request): Observable<any> {
        const apiUrl = 'CompanyCsr/DeleteCompanyCsr';
        return this.httpBaseService.post(apiUrl, request);
    }

    public CreateCompanyProgramManager(request): Observable<any> {
        const apiUrl = 'CompanyProgramManager/CreateCompanyProgramManager';
        return this.httpBaseService.post(apiUrl, request);
    }

    public DeleteCompanyProgramManager(request): Observable<any> {
        const apiUrl = 'CompanyProgramManager/DeleteCompanyProgramManager';
        return this.httpBaseService.post(apiUrl, request);
    }


    public getCompanyListUsingServerSidePagination(request): Observable<any> {
        const apiUrl = 'Company/GetCompanyListUsingServerSidePagination';
        return this.httpBaseService.post(apiUrl, request);
    }

    public getCompanyBasedOnSearchString(request): Observable<any> {
        const apiUrl = 'Company/GetCompanyBasedOnSearchString';
        return this.httpBaseService.post(apiUrl, request);
    }

    public GetCompanyBasedOnSearchStringExcludingParenCompanyAndChildCompany(request): Observable<any> {
        const apiUrl = 'Company/GetCompanyBasedOnSearchStringExcludingParenCompanyAndChildCompany';
        return this.httpBaseService.post(apiUrl, request);
    }

    public GetCompanyBasedOnSearchStringParentChild(request, companyId): Observable<any> {
        let apiUrl = 'Company/GetCompanyBasedOnSearchString';
        if (companyId) {
            request.companyId = companyId;
            apiUrl = 'Company/GetCompanyBasedOnSearchStringExcludingParenCompanyAndChildCompany';
        }
        return this.httpBaseService.post(apiUrl, request);
    }

    public getCompanyAddressesAndMainContactByCompanyId(id, userId): Observable<any> {
        const apiUrl = 'Company/GetCompanyAddressesAndMainContactByCompanyId/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public getCompanyDetailsByCompanyId(id, userId): Observable<any> {
        const apiUrl = 'Company/GetCompanyDetailsByCompanyId/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }


    public getContactsAssociatedWithCompany(companyId, userId): Observable<any> {
        const apiUrl = 'Contact/GetContactsAssociatedWithCompany/' + companyId + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public getIATFSiteTypesOfuser(userId): Observable<any> {
        const apiUrl = 'IATFSiteType/GetIATFSiteTypes/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public createCompanyArchiveInformation(payload): Observable<any> {
        const apiUrl = 'CompanyArchiveInformation/CreateCompanyArchiveInformation'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public updateCompanyArchiveInformation(payload): Observable<any> {
        const apiUrl = 'CompanyArchiveInformation/UpdateCompanyArchiveInformation'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public deleteCompanyArchiveInformation(payload): Observable<any> {
        const apiUrl = 'CompanyArchiveInformation/DeleteCompanyArchiveInformation'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public getCompanyArchiveInformationsByQuoteId(id, userId): Observable<any> {
        const apiUrl = 'CompanyArchiveInformation/GetCompanyArchiveInformationsByQuoteId/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }
}

