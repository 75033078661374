<div class="input-group">
    <input  class="form-control"
            [(ngModel)]="data"
            [type]="isVisible?'text':'password'"
            (change)="onChange()">
    <div class="input-group-append">
        <button class="btn btn-primary" type="button" (click)="toggleVisibility()">
            <i *ngIf="!isVisible" class="far fa-eye text-white"></i> <span *ngIf="!isVisible"> {{"Show" | localize}}</span>
            <i *ngIf="isVisible" class="far fa-eye-slash text-white"></i> <span *ngIf="isVisible"> {{"Hide" | localize}}</span>
        </button>
    </div>
</div>
