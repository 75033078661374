import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'usdCurrency'
})
export class UsdCurrencyPipe implements PipeTransform {

  transform(value: number): string {
    if (value !== null && value !== undefined) {
      const formattedValue = new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2
      }).format(value);
      
      return `${formattedValue} USD`;
    }
    return '';
  }

}
