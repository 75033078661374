import { Component, Injector, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CparsService } from '@app/shared/services/cpars/cpars.service';
import { AppConsts } from '@shared/AppConsts';
import { AppComponentBase } from '@shared/common/app-component-base';
import CustomStore from 'devextreme/data/custom_store';
import * as moment from 'moment';

@Component({
  selector: 'app-cpars-grid',
  templateUrl: './cpars-grid.component.html',
  styleUrls: ['./cpars-grid.component.css']
})
export class CparsGridComponent extends AppComponentBase implements OnInit {

  @Input('clientId') clientId;
  @Input('companyId') companyId;
  @Input('locationId') locationId;
  @Input('locationList') locationList;
  @Input('isArchived') isArchived;
  
  searchString = '';
  cparsList: any = [];
  companyLocationId;
  isView: boolean = window.location.pathname.indexOf('/view') === -1 ? false : true;

  constructor(private injector: Injector,
    private cparsService: CparsService,
    private router: Router) {
    super(injector);
  }

  ngOnInit(): void {
    this.getCPARsList();
  }

  editAudit = (e) => {
    this.router.navigate([`app/cpars/${e.row.data.id}/edit`])
  }

  viewAuditDetails(data: any) {
    this.router.navigate([`app/cpars/${data.id}/view`]);
  }

  getLocaleDate(date) {
    if (date) {
      return moment(date).format(AppConsts.momentDateOnly);
    }
    else {
      return "";
    }
  }

  // getCPARsList() {
  //   this.spinnerService.show();
  //   this.cparsService.getClientCPARs(this.appSession.user.id).subscribe(
  //     (data: any) => {
  //       this.spinnerService.hide();
  //       this.cparsList = data;
  //     },
  //     (error: any) => {
  //       abp.notify.error("", "Failed to fetch CPARs", { showCloseButton: true, timer: 10000 });
  //       this.spinnerService.hide();
  //     });
  // }

  // getCPARsList() {
  //   var self = this;
  //   this.cparsList = new CustomStore({
  //     key: "id",
  //     load: function (loadOptions: any) {
  //       const searchString = loadOptions.filter ? (loadOptions.filter[0].filterValue + '').trim() : ""
  //       let dto = {
  //         searchString: searchString,
  //         pageSize: loadOptions.take,
  //         pageNumber: (self.searchString != searchString) ? 1 : (loadOptions.skip / loadOptions.take) + 1,
  //         userId: self.appSession.user.id
  //       }
  //       let searchDto;
  //       if (self.clientId) {
  //         searchDto = {
  //           ...dto,
  //           clientId: self.clientId
  //         }
  //       }
  //       else {
  //         searchDto = dto;
  //       }
  //       self.spinnerService.show();
  //       self.searchString = searchDto.searchString;

  //       return self.cparsService.getClientCPARsUsingServerSidePagination(searchDto)
  //         .toPromise()
  //         .then((data: any) => {
  //           self.spinnerService.hide();
  //           if (!self.clientId) {

  //           }
  //           return {
  //             data: data.quoteList ? data.quoteList : [],
  //             totalCount: data.totalItemsCount
  //           };
  //         })
  //         .catch(error => {
  //           self.spinnerService.hide();
  //           abp.log.error(error);
  //           abp.notify.error("", "Failed to retrieve quote list", { showCloseButton: true, timer: 10000 });
  //         });
  //     }
  //   });
  // }

  getCPARsList() {
    var self = this;
    this.cparsList = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        const searchString = loadOptions.filter ? (loadOptions.filter[0].filterValue + '').trim() : ""
        let dto = {
          searchString: searchString,
          pageSize: loadOptions.take,
          pageNumber: (self.searchString != searchString) ? 1 : (loadOptions.skip / loadOptions.take) + 1,
          userId: self.appSession.user.id
        }
        let searchDto;
        if (self.clientId) {
          searchDto = {
            ...dto,
            clientId: self.clientId
          }
        }
        else if (self.clientId) {
          searchDto = {
            ...dto,
            clientId: self.clientId
          }
        }
        else {
          searchDto = dto;
        }

        if (self.companyLocationId || self.locationId) {
          searchDto = {
            ...searchDto,
            companyLocationId: self.companyLocationId ? self.companyLocationId : self.locationId
          }
        }
        self.spinnerService.show();
        self.searchString = searchDto.searchString;

        return self.cparsService.getClientCPARsUsingServerSidePagination(searchDto)
          .toPromise()
          .then((data: any) => {
            const result = data;
            self.spinnerService.hide();
            if (!self.clientId) {

            }
            return {
              data: data.clientCPARList ? data.clientCPARList : [],
              totalCount: data.totalItemsCount
            };
          })
          .catch(error => {
            self.spinnerService.hide();
            abp.log.error(error);
            abp.notify.error("", "Failed to retrieve CPAR list", { showCloseButton: true, timer: 10000 });
          });
      }
    });
  }


  addCPAR() {
    if (this.clientId) {
      this.router.navigate([`app/cpars/add/client/${this.clientId}`]);
    }
    if (this.companyId) {
      this.router.navigate([`app/cpars/add/company/${this.companyId}/location/${this.locationId}`]);
    }
    else {
      this.router.navigate([`app/cpars/add`]);
    }
  }

  onAuditLocationChange(data) {
    this.companyLocationId = data;
    this.getCPARsList();
  }

  clearLocationSelection() {
    this.companyLocationId = null;
    this.getCPARsList();
  }

  onToolbarPreparing(e) {
    if (this.clientId) {
      e.toolbarOptions.items.push({
        location: 'before',
        template: 'pick-audit-location',
        options: {
          width: 200
        }
      });
    }
    e.toolbarOptions.items.push({
      location: 'after',
      widget: 'dxButton',
      options: {
        icon: "fa fa-plus",
        text: 'Add',
        onClick: this.addCPAR.bind(this)
      }
    });
  }

}

