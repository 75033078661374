import { SurveillanceScheduleDto } from "@app/shared/models/surveillance-schedule/surveillance-schedule-dto";

export class AppEditionExpireAction {
  static DeactiveTenant = 'DeactiveTenant';
  static AssignToAnotherEdition = 'AssignToAnotherEdition';
}

export class FromPage {
  static PersonList = 'PersonList';
  static AuditorList = 'AuditorList';
  static ContactList = 'ContactList';
  static CreateAuditor = 'CreateAuditor';
  static CreateContact = 'CreateContact';
  static ViewPerson = 'ViewPerson';
  static CompanyContactList = 'CompanyContactList';
  static ClientContactList = 'ClientContactList';
  static ClientList = 'ClientList';
  static Quote = 'Quote';
  static ContractReview = 'ContractReview';
  static Audit = 'Audit';
  static Ncr = 'Ncr';
  static Certificate = 'Certificate';
  static Checklist = 'Checklist';  
  static ContactDetail = 'ContactDetail';  
  static CPARs = 'CPARs';  
}

export class Tabs{
  static QuoteInfo = 'QuoteInfo';
  static Locations = 'Locations';
}

export enum StatusRuleErrorType {
  MissingRequiredFields = 0,
  ReadOnlyRecord = 1,
  InvalidStatusChange = 2
}




export class ManageAuditsFromPage {
  static scheduledAudits = 'managesaudits';
  static unScheduledAudits = "unscheduled-audits";
}

export class PhoneType {
  static Cell = 'Cell';
  static Phone = 'Phone';
}

export class EmailType {
  static Main = 'Main';
}

export class AddressType {
  static Main = 'Main';
}

export class CompanyType {
  static NTS = 9002000000;
}

export class PersonStatus {
  static Active = 'Active';
  static Inactive = 'Inactive';
}

export class AuditorStatus {
  static Active = 'Active';
}

export class ContactStatus {
  static Active = 'Active';
}


export class NoVisitTransferReason {
  static Others = 9070000004;
}


export class TransferReason {
  static Others = 9069000005;
}

export class PeopleRoleType {
  static CSR = "CSR";
  static ProgramManager = "Program Manager";
  static OfficeManager = "Office Manager";
  static AuditorManager = "Auditor Manager";
  static QuoteReviewer = "Quote Reviewer";
  static SalesRep = "Sales Rep";
  static QuoteApprover = "Quote Approver";
  static NQAConsultant = "NQA Consultant";
  static SalesPerson = "Sales Person";
  static Employee = "Employee";
  static Auditor = "Auditor";
}


export class AuditType {
  static Surveillance = "Surveillance";
  static Reassessment = "Reassessment";

}

export class ClientTab {
  static Main = 'Main';
  static Contacts = 'Contacts';
  static Quotes = 'Quotes'; 
  static ContractReviews = 'Contract Reviews'; 
  static Audits = 'Audits'; 
  static Certificates = 'Certificate Management'; 
  static Ncr = 'NCR'; 
}

export class AuditorTab {
  static Main = 'Main';
  static AuditorCompensation = 'Auditor Compensation';
}

export class QuoteTab {
  static QuoteInfo = 'Quote Info';
  static SalesInfo = 'Sales Info';
  static Locations = 'Locations'; 
}

export class CPARsTab {
  static ContainmentAction = 'Containment Action';
  static StatusHistory = 'Status History';
}

export class ContractReviewTab {
  static ClientInfo = 'Client Info';
  static Locations = 'Locations'; 
}

export const SURVEILLANCE_SCHEDULE_QUOTE: SurveillanceScheduleDto[] = [
  { id: 6, name: "6" },
  { id: 9, name: "9" },
  { id: 12, name: "12" }
];


export const SURVEILLANCE_SCHEDULE: SurveillanceScheduleDto[] = [
  { id: 30, name: "30 Days" },
  { id: 60, name: "60 Days" },
  { id: 90, name: "90 Days" }
];

export class AuditPlanType {
  static Standalone = "Standalone";
  static Integrated = "Integrated";
}

export class CommentType {
  static NcrContainment = "Ncr Containment";
  static NcrContainmentObjectiveEvidence = "Ncr Containment Objective Evidence";
  static NcrCorrection = "Ncr Correction";
  static NcrCorrectionObjectiveEvidence = "Ncr Correction Objective Evidence";
  static NcrCorrectiveAction = "Ncr Corrective Action";
  static NcrCorrectiveActionObjectiveEvidence = "Ncr Corrective Action Objective Evidence";
  static NcrRootCause = "Ncr Root Cause";
}
