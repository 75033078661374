import { AddWidgetModalComponent } from './customizable-dashboard/add-widget-modal/add-widget-modal.component';
import { AppAuthService } from './auth/app-auth.service';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AppNavigationService } from '@app/shared/layout/nav/app-navigation.service';
import { AppRouteGuard } from './auth/auth-route-guard';
import { AuditStandardService } from '../services/audit-standard/audit-standard.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BlueOceanCommonModule } from '@shared/common/common.module';
import { BsDatepickerModule, BsDatepickerConfig, BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BusinessTypeService } from '../services/business-type/business-type.service';
import { CheckboxInputTypeComponent } from './input-types/checkbox-input-type/checkbox-input-type.component';
import { ClassificationService } from '../services/classification/classification.service';
import { ComboboxInputTypeComponent } from './input-types/combobox-input-type/combobox-input-type.component';
import { CommonLookupModalComponent } from './lookup/common-lookup-modal.component';
import { CommonModule } from '@angular/common';
import { CompanyStatusService } from '../services/company-status/company-status.service';
import { CompanyTypeService } from '../services/company-type/company-type.service';
import { CountoModule } from 'angular2-counto';
import { CountryService } from '../services/country/country.service';
import { CurrencyUnitsService } from '../services/currency-units/currency-units.service';
import { CustomizableDashboardComponent } from './customizable-dashboard/customizable-dashboard.component';
import { DashboardViewConfigurationService } from './customizable-dashboard/dashboard-view-configuration.service';
import { DatePickerInitialValueSetterDirective } from './timing/date-picker-initial-value.directive';
import { DateRangePickerInitialValueSetterDirective } from './timing/date-range-picker-initial-value.directive';
import { DateTimeService } from './timing/date-time.service';
import { DxDataGridModule, DxDateBoxModule, DxListModule, DxPopoverModule, DxPopupModule, DxRadioGroupModule, DxTreeListModule, DxScrollViewModule } from 'devextreme-angular';
import { EditorModule } from 'primeng/editor';
import { EntityChangeDetailModalComponent } from './entityHistory/entity-change-detail-modal.component';
import { EntityTypeHistoryModalComponent } from './entityHistory/entity-type-history-modal.component';
import { FilterDateRangePickerComponent } from './customizable-dashboard/filters/filter-date-range-picker/filter-date-range-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { GridsterModule } from 'angular-gridster2';
import { HttpBaseService } from '../services/httpbase.service';
import { InputMaskModule } from 'primeng/inputmask';
import { KeyValueListManagerComponent } from './key-value-list-manager/key-value-list-manager.component';
import { LegalStatusService } from '../services/legal-status/legal-status.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MultipleSelectComboboxInputTypeComponent } from './input-types/multiple-select-combobox-input-type/multiple-select-combobox-input-type.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgWizardConfig, THEME, NgWizardModule } from 'ng-wizard';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { PaginatorModule } from 'primeng/paginator';
import { PasswordInputWithShowButtonComponent } from './password-input-with-show-button/password-input-with-show-button.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PieChartModule, AreaChartModule, LineChartModule, BarChartModule, NumberCardModule } from '@swimlane/ngx-charts';
import { RegionService } from '../services/region/region.service';
import { RegistrationStatusService } from '../services/registration-status/registration-status.service';
import { SingleLineStringInputTypeComponent } from './input-types/single-line-string-input-type/single-line-string-input-type.component';
import { SponsorService } from '../services/sponsor/sponsor.service';
import { SubHeaderComponent } from '@app/shared/common/sub-header/sub-header.component';
import { TableModule } from 'primeng/table';
import { MenuModule } from 'primeng/menu';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimeZoneComboComponent } from './timing/timezone-combo.component';
import { UtilsModule } from '@shared/utils/utils.module';
import { WidgetDailySalesComponent } from './customizable-dashboard/widgets/widget-daily-sales/widget-daily-sales.component';
import { WidgetEditionStatisticsComponent } from './customizable-dashboard/widgets/widget-edition-statistics/widget-edition-statistics.component';
import { WidgetGeneralStatsComponent } from './customizable-dashboard/widgets/widget-general-stats/widget-general-stats.component';
import { WidgetHostTopStatsComponent } from './customizable-dashboard/widgets/widget-host-top-stats/widget-host-top-stats.component';
import { WidgetIncomeStatisticsComponent } from './customizable-dashboard/widgets/widget-income-statistics/widget-income-statistics.component';
import { WidgetMemberActivityComponent } from './customizable-dashboard/widgets/widget-member-activity/widget-member-activity.component';
import { WidgetProfitShareComponent } from './customizable-dashboard/widgets/widget-profit-share/widget-profit-share.component';
import { WidgetRecentTenantsComponent } from './customizable-dashboard/widgets/widget-recent-tenants/widget-recent-tenants.component';
import { WidgetRegionalStatsComponent } from './customizable-dashboard/widgets/widget-regional-stats/widget-regional-stats.component';
import { WidgetSalesSummaryComponent } from './customizable-dashboard/widgets/widget-sales-summary/widget-sales-summary.component';
import { WidgetSubscriptionExpiringTenantsComponent } from './customizable-dashboard/widgets/widget-subscription-expiring-tenants/widget-subscription-expiring-tenants.component';
import { WidgetTopStatsComponent } from './customizable-dashboard/widgets/widget-top-stats/widget-top-stats.component';
import { PickListModule } from 'primeng/picklist';
import { ListboxModule } from 'primeng/listbox';
import { WidgetFinanceRevenueSummaryComponent } from './customizable-dashboard/widgets/widget-finance-revenue-summary/widget-finance-revenue-summary.component';
import { WidgetClientsToInvoiceComponent } from './customizable-dashboard/widgets/widget-clients-to-invoice/widget-clients-to-invoice.component';
import { WidgetMoneyThisWeekComponent } from './customizable-dashboard/widgets/widget-money-this-week/widget-money-this-week.component';
import { WidgetFutureRevenueComponent } from './customizable-dashboard/widgets/widget-future-revenue/widget-future-revenue.component';
import { WidgetSiteConcernsComponent } from './customizable-dashboard/widgets/widget-site-concerns/widget-site-concerns.component';
import { WidgetApplicationNewComponent } from './customizable-dashboard/widgets/widget-application-new/widget-application-new.component';
import { WidgetApplicationQuotedComponent } from './customizable-dashboard/widgets/widget-application-quoted/widget-application-quoted.component';
import { WidgetApplicationAwardedComponent } from './customizable-dashboard/widgets/widget-application-awarded/widget-application-awarded.component';
import { WidgetAuditsByDateComponent } from './customizable-dashboard/widgets/widget-audits-by-date/widget-audits-by-date.component';
import { WidgetAuditsOverdueComponent } from './customizable-dashboard/widgets/widget-audits-overdue/widget-audits-overdue.component';
import { WidgetQuotesToCompleteComponent } from './customizable-dashboard/widgets/widget-quotes-to-complete/widget-quotes-to-complete.component';
import { WidgetApprovedQuotesComponent } from './customizable-dashboard/widgets/widget-approved-quotes/widget-approved-quotes.component';
import { WidgetQuotesAwaitingContractComponent } from './customizable-dashboard/widgets/widget-quotes-awaiting-contract/widget-quotes-awaiting-contract.component';
import { WidgetRevisedQuotesComponent } from './customizable-dashboard/widgets/widget-revised-quotes/widget-revised-quotes.component';
import { StimulsoftViewerModule } from 'stimulsoft-viewer-angular';
import { StimulsoftDesignerModule } from 'stimulsoft-designer-angular';
import { CompanyPopupComponent } from './company-popup/company-popup.component';
import { HighlightPipe } from '../pipes/highlight.pipe';
import { FileUploadModule as PrimeNgFileUploadModule } from 'primeng/fileupload';
import { SidebarModule } from 'primeng/sidebar';
import { CompanyTypeaheadDropdownComponent } from './company-typeahead-dropdown/company-typeahead-dropdown.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderByPipe } from '../pipes/orderby.pipe';
import { FilterVisibilityPipe } from '../pipes/filter.pipe'; 
import { MultiSelectModule } from 'primeng/multiselect';
import { OrganizationCodeComponent } from './organization-code/organization-code.component';
import { OutboundMessagesComponent } from './outbound-messages/outbound-messages.component';
import { OutboundMessageService } from '../services/outbound-messages/outbound-messages.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpRequestInterceptor } from './interceptors/httprequest-interceptor';
import { NotificationTabComponent } from './notification-tab/notification-tab.component';
import { CompanyMainAddressChangeModalComponent } from './company-main-address-change-modal/company-main-address-change-modal.component';
import { AuditService } from '../services/audit/audit.service';
import { QuoteGridComponent } from '@app/quote/quote-grid/quote-grid.component';
import { ContractReviewGridComponent } from '@app/quote/contract-review-grid/contract-review-grid.component';
import { TemporaryHeaderComponent } from './temporary-header/temporary-header.component';
import { AuditGridComponent } from '@app/schedule/audit-grid/audit-grid.component';
import { CertificateGridComponent } from '@app/certificate/certificate-grid/certificate-grid.component';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DragAndDropModule } from 'angular-draggable-droppable';
import { NcrGridComponent } from '@app/ncr/ncr-grid/ncr-grid.component';
import { ChecklistGridComponent } from '@app/checklist/checklist-grid/checklist-grid.component';
import { JustificationGridComponent } from './justification/justification-grid/justification-grid.component';
import { JustificationModalComponent } from './justification/justification-modal/justification-modal.component';
import { CparsGridComponent } from '@app/cpars/cpars-grid/cpars-grid.component';
import { UsdCurrencyPipe } from '../pipes/usd-currency.pipe';

const ngWizardConfig: NgWizardConfig = {
    theme: THEME.default
};

@NgModule({
    imports: [
        AppBsModalModule,
        AreaChartModule,
        AutoCompleteModule,
        DragAndDropModule,
        ContextMenuModule,
        BarChartModule,
        BlueOceanCommonModule,
        BsDatepickerModule.forRoot(),
        BsDropdownModule.forRoot(),
        CommonModule,
        CountoModule,
        DxDataGridModule,
        DxListModule,
        DxDateBoxModule,
        DxPopoverModule,
        DxPopupModule,
        DxRadioGroupModule,
        DxScrollViewModule,
        DxTreeListModule,
        EditorModule,
        FormsModule,
        GooglePlaceModule,
        GridsterModule,
        InputMaskModule,
        LineChartModule,
        ListboxModule,
        MenuModule,
        ModalModule.forRoot(),
        MultiSelectModule,
        NgbModule,        
        NgSelectModule,
        NgWizardModule.forRoot(ngWizardConfig),
        NumberCardModule,
        PaginatorModule,
        PerfectScrollbarModule,
        PickListModule,
        PieChartModule,
        PrimeNgFileUploadModule,
        ReactiveFormsModule,
        SidebarModule,
        StimulsoftDesignerModule,
        StimulsoftViewerModule,
        TableModule,
        TabsModule.forRoot(),
        UtilsModule,
    ],
    declarations: [
        AddWidgetModalComponent,
        CheckboxInputTypeComponent,
        ComboboxInputTypeComponent,
        CommonLookupModalComponent,
        CompanyPopupComponent,
        CompanyTypeaheadDropdownComponent,
        CustomizableDashboardComponent,
        DatePickerInitialValueSetterDirective,
        DateRangePickerInitialValueSetterDirective,
        EntityChangeDetailModalComponent,
        EntityTypeHistoryModalComponent,
        FilterDateRangePickerComponent,
        FilterVisibilityPipe, 
        HighlightPipe,
        UsdCurrencyPipe,
        KeyValueListManagerComponent,
        MultipleSelectComboboxInputTypeComponent,
        OrderByPipe,
        OrganizationCodeComponent,
        PasswordInputWithShowButtonComponent,
        SingleLineStringInputTypeComponent,
        SubHeaderComponent,
        TimeZoneComboComponent,
        WidgetApplicationAwardedComponent,
        WidgetApplicationNewComponent,
        WidgetApplicationQuotedComponent,
        WidgetApprovedQuotesComponent,
        WidgetAuditsByDateComponent,
        WidgetAuditsOverdueComponent,
        WidgetClientsToInvoiceComponent,
        WidgetDailySalesComponent,
        WidgetEditionStatisticsComponent,
        WidgetFinanceRevenueSummaryComponent,
        WidgetFutureRevenueComponent,
        WidgetGeneralStatsComponent,
        WidgetHostTopStatsComponent,
        WidgetIncomeStatisticsComponent,
        WidgetMemberActivityComponent,
        WidgetMoneyThisWeekComponent,
        WidgetProfitShareComponent,
        WidgetQuotesAwaitingContractComponent,
        WidgetQuotesToCompleteComponent,
        WidgetRecentTenantsComponent,
        WidgetRegionalStatsComponent,
        WidgetRevisedQuotesComponent,
        WidgetSalesSummaryComponent,
        WidgetSiteConcernsComponent,
        WidgetSubscriptionExpiringTenantsComponent,
        WidgetTopStatsComponent,
        FilterDateRangePickerComponent,
        AddWidgetModalComponent,
        SingleLineStringInputTypeComponent,
        ComboboxInputTypeComponent,
        CheckboxInputTypeComponent,
        MultipleSelectComboboxInputTypeComponent,
        PasswordInputWithShowButtonComponent,
        KeyValueListManagerComponent,
        SubHeaderComponent,
        WidgetClientsToInvoiceComponent,
        WidgetMoneyThisWeekComponent,
        WidgetFutureRevenueComponent,
        WidgetSiteConcernsComponent,
        WidgetApplicationNewComponent,
        WidgetApplicationQuotedComponent,
        WidgetApplicationAwardedComponent,
        WidgetAuditsByDateComponent,
        WidgetAuditsOverdueComponent,
        WidgetQuotesToCompleteComponent,
        WidgetApprovedQuotesComponent,
        WidgetQuotesAwaitingContractComponent,
        WidgetRevisedQuotesComponent,
        CompanyPopupComponent,
        HighlightPipe,
        CompanyTypeaheadDropdownComponent,
        OrderByPipe,
        FilterVisibilityPipe, 
        OrganizationCodeComponent, 
        OutboundMessagesComponent, 
        CompanyMainAddressChangeModalComponent,
        QuoteGridComponent,
        ContractReviewGridComponent,
        AuditGridComponent,
        CertificateGridComponent,
        NcrGridComponent,
        ChecklistGridComponent,
        JustificationGridComponent,
        JustificationModalComponent,
        CparsGridComponent
    ],
    exports: [
        AppBsModalModule,
        AutoCompleteModule,
        DragAndDropModule,
        ContextMenuModule,
        BsDatepickerModule,
        BsDropdownModule,
        CommonLookupModalComponent,
        CompanyPopupComponent,
        CompanyTypeaheadDropdownComponent,
        CustomizableDashboardComponent,
        DatePickerInitialValueSetterDirective,
        DateRangePickerInitialValueSetterDirective,
        DxDataGridModule,
        DxDateBoxModule,
        DxPopoverModule,
        DxPopupModule,
        DxRadioGroupModule,
        DxScrollViewModule,
        DxTreeListModule,
        EntityChangeDetailModalComponent,
        EntityTypeHistoryModalComponent,
        FilterVisibilityPipe,
        FormsModule,
        GooglePlaceModule,
        HighlightPipe,
        UsdCurrencyPipe,
        InputMaskModule,
        KeyValueListManagerComponent,
        ListboxModule,
        MenuModule,
        NgbModule,
        NgSelectModule,
        NgWizardModule,
        NumberCardModule,
        OrderByPipe,
        OrganizationCodeComponent,
        PasswordInputWithShowButtonComponent,
        PickListModule,
        PrimeNgFileUploadModule,
        ReactiveFormsModule,
        SidebarModule,
        StimulsoftDesignerModule,
        StimulsoftViewerModule,
        SubHeaderComponent,
        TabsModule,
        TimeZoneComboComponent,
        MenuModule,
        AutoCompleteModule,
        NgSelectModule,
        DxDataGridModule,
        DxPopoverModule,
        DxRadioGroupModule,
        DxTreeListModule,
        DxListModule,
        FormsModule,
        ReactiveFormsModule,
        InputMaskModule,
        GooglePlaceModule,
        NgWizardModule,
        DxDateBoxModule,
        NumberCardModule,
        ListboxModule,
        PickListModule,
        StimulsoftViewerModule,
        StimulsoftDesignerModule,
        CompanyPopupComponent,
        HighlightPipe,
        PrimeNgFileUploadModule,
        SidebarModule,
        CompanyTypeaheadDropdownComponent,
        BsDatepickerModule,
        UtilsModule,
        AppBsModalModule,
        NgbModule,
        OrderByPipe,
        FilterVisibilityPipe,
        OrganizationCodeComponent,
        OutboundMessagesComponent,
        CompanyMainAddressChangeModalComponent,
        QuoteGridComponent,
        ContractReviewGridComponent,
        AuditGridComponent,
        CertificateGridComponent,
        NcrGridComponent,
        ChecklistGridComponent,
        JustificationGridComponent,
        JustificationModalComponent,
        CparsGridComponent
    ],
    providers: [
        HttpBaseService,
        RegionService,
        RegistrationStatusService,
        BusinessTypeService,
        CountryService,
        AuditStandardService,
        AuditService,
        DateTimeService,
        AppLocalizationService,
        AppNavigationService,
        AuditStandardService,
        BusinessTypeService,
        ClassificationService,
        CompanyStatusService,
        CompanyTypeService,
        CountryService,
        CurrencyUnitsService,
        ClassificationService,
        OutboundMessageService,
        DashboardViewConfigurationService,
        DateTimeService,
        HttpBaseService,
        LegalStatusService,
        RegionService,
        RegistrationStatusService,
        SponsorService,
        { provide: BsDatepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerConfig },
        { provide: BsDaterangepickerConfig, useFactory: NgxBootstrapDatePickerConfigService.getDaterangepickerConfig },
        { 
            provide: HTTP_INTERCEPTORS, 
            useClass: HttpRequestInterceptor, 
            multi: true 
        }
    ],

    entryComponents: [
        CheckboxInputTypeComponent,
        ComboboxInputTypeComponent,
        FilterDateRangePickerComponent,
        MultipleSelectComboboxInputTypeComponent,
        SingleLineStringInputTypeComponent,
        WidgetDailySalesComponent,
        WidgetEditionStatisticsComponent,
        WidgetFinanceRevenueSummaryComponent,
        WidgetGeneralStatsComponent,
        WidgetHostTopStatsComponent,
        WidgetIncomeStatisticsComponent,
        WidgetMemberActivityComponent,
        WidgetProfitShareComponent,
        WidgetRecentTenantsComponent,
        WidgetRegionalStatsComponent,
        WidgetSalesSummaryComponent,
        WidgetSubscriptionExpiringTenantsComponent,
        WidgetTopStatsComponent,
    ]
})
export class AppCommonModule {
    static forRoot(): ModuleWithProviders<AppCommonModule> {
        return {
            ngModule: AppCommonModule,
            providers: [
                AppAuthService,
                AppRouteGuard
            ]
        };
    }
}
