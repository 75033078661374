import { Injectable } from '@angular/core';
import { SurveillanceScheduleDto } from '@app/shared/models/surveillance-schedule/surveillance-schedule-dto';
import { HttpBaseService } from '@app/shared/services/httpbase.service';
import { SURVEILLANCE_SCHEDULE_QUOTE } from '@shared/AppEnums';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class QuoteService {

    constructor(private httpBaseService: HttpBaseService) { }

    public getQuotesUsingServerSidePaginationByUserId(payload): Observable<any> {
        const apiUrl = 'Quote/GetQuotesUsingServerSidePaginationByUserId';
        return this.httpBaseService.post(apiUrl, payload)
    }

    public getQuotes(userId): Observable<any> {
        const apiUrl = 'Quote/GetQuotes/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public getQuoteStandards(userId): Observable<any> {
        const apiUrl = 'QuoteStandard/GetQuoteStandards/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public getQuoteConsolidatedById(id, userId): Observable<any> {
        const apiUrl = 'Quote/GetQuoteConsolidatedById/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public createQuote(data): Observable<any> {
        const apiUrl = 'Quote/CreateQuoteConsolidated'
        return this.httpBaseService.post(apiUrl, data);
    }

    public updateQuote(data): Observable<any> {
        const apiUrl = 'Quote/UpdateQuote'
        return this.httpBaseService.post(apiUrl, data);
    }

    public updateQuoteStatus(quoteId, quoteStatusId, userId): Observable<any> {
        const apiUrl = 'Quote/UpdateQuoteStatus/' + quoteId + '/' + quoteStatusId + '/' + userId
        return this.httpBaseService.post(apiUrl, null);
    }

    public deleteQuote(data): Observable<any> {
        const apiUrl = 'Quote/DeleteQuote'
        return this.httpBaseService.post(apiUrl, data);
    }

    public updateQuoteSalesInfo(data): Observable<any> {
        const apiUrl = 'QuoteSalesInfo/UpdateQuoteSalesInfo'
        return this.httpBaseService.post(apiUrl, data);
    }

    public createQuoteSalesInfo(data): Observable<any> {
        const apiUrl = 'QuoteSalesInfo/CreateQuoteSalesInfo'
        return this.httpBaseService.post(apiUrl, data);
    }

    public createQuoteCertSeed(data): Observable<any> {
        const apiUrl = 'QuoteCertSeed/CreateQuoteCertSeed'
        return this.httpBaseService.post(apiUrl, data);
    }

    public updateQuoteCertSeed(data): Observable<any> {
        const apiUrl = 'QuoteCertSeed/UpdateQuoteCertSeed'
        return this.httpBaseService.post(apiUrl, data);
    }

    public createQuoteAuditType(data): Observable<any> {
        const apiUrl = 'QuoteStandardGroupLocationsAndAuditType/CreateQuoteStandardGroupLocationsAndAuditType'
        return this.httpBaseService.post(apiUrl, data);
    }

    public updateQuoteAuditType(data): Observable<any> {
        const apiUrl = 'QuoteStandardGroupLocationsAndAuditType/UpdateQuoteStandardGroupLocationsAndAuditType'
        return this.httpBaseService.post(apiUrl, data);
    }

    public getQuoteAuditTypesByQuoteId(id, userId): Observable<any> {
        const apiUrl = 'QuoteStandardGroupLocationsAndAuditType/GetQuoteStandardGroupLocationsAndAuditTypesByQuoteId/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    public createQuoteStandard(data): Observable<any> {
        const apiUrl = 'QuoteStandard/CreateQuoteStandard'
        return this.httpBaseService.post(apiUrl, data);
    }

    public deleteQuoteStandard(data): Observable<any> {
        const apiUrl = 'QuoteStandard/DeleteQuoteStandard'
        return this.httpBaseService.post(apiUrl, data);
    }

    public deleteQuoteStandardGroupLocationsAndAuditType(data): Observable<any> {
        const apiUrl = 'QuoteStandardGroupLocationsAndAuditType/DeleteQuoteStandardGroupLocationsAndAuditType'
        return this.httpBaseService.post(apiUrl, data);
    }

    public deleteQuoteStandardGroupLocationsAndAuditTypes(data): Observable<any> {
        const apiUrl = 'QuoteStandardGroupLocationsAndAuditType/DeleteQuoteStandardGroupLocationsAndAuditTypes'
        return this.httpBaseService.post(apiUrl, data);
    }

    public createQuoteJustification(data): Observable<any> {
        const apiUrl = 'QuoteJustification/CreateQuoteJustification'
        return this.httpBaseService.post(apiUrl, data);
    }

    public updateQuoteJustification(data): Observable<any> {
        const apiUrl = 'QuoteJustification/UpdateQuoteJustification'
        return this.httpBaseService.post(apiUrl, data);
    }

    public createQuoteTransfer(data): Observable<any> {
        const apiUrl = 'QuoteTransfer/CreateQuoteTransfer'
        return this.httpBaseService.post(apiUrl, data);
    }

    public updateQuoteTransfer(data): Observable<any> {
        const apiUrl = 'QuoteTransfer/UpdateQuoteTransfer'
        return this.httpBaseService.post(apiUrl, data);
    }

    public deleteQuoteTransfer(data): Observable<any> {
        const apiUrl = 'QuoteTransfer/DeleteQuoteTransfer'
        return this.httpBaseService.post(apiUrl, data);
    }

    public createQuoteSiteFee(data): Observable<any> {
        const apiUrl = 'QuoteSiteFee/CreateQuoteSiteFee'
        return this.httpBaseService.post(apiUrl, data);
    }

    public updateQuoteSiteFee(data): Observable<any> {
        const apiUrl = 'QuoteSiteFee/UpdateQuoteSiteFee'
        return this.httpBaseService.post(apiUrl, data);
    }

    public deleteQuoteSiteFee(data): Observable<any> {
        const apiUrl = 'QuoteSiteFee/DeleteQuoteSiteFee'
        return this.httpBaseService.post(apiUrl, data);
    }

    public createQuoteAttachment(data): Observable<any> {
        const apiUrl = 'QuoteAttachment/CreateQuoteAttachment'
        return this.httpBaseService.post(apiUrl, data);
    }

    public updateQuoteAttachment(data): Observable<any> {
        const apiUrl = 'QuoteAttachment/UpdateQuoteAttachment'
        return this.httpBaseService.post(apiUrl, data);
    }

    public deleteQuoteAttachment(data): Observable<any> {
        const apiUrl = 'QuoteAttachment/DeleteQuoteAttachment'
        return this.httpBaseService.post(apiUrl, data);
    }

    public updateQuoteJustificationCommonFields(data): Observable<any> {
        const apiUrl = 'Quote/UpdateQuoteJustificationCommonFields'
        return this.httpBaseService.post(apiUrl, data);
    }

    public createQuoteSalesPerson(data): Observable<any> {
        const apiUrl = 'QuoteSalesPerson/CreateQuoteSalesPerson'
        return this.httpBaseService.post(apiUrl, data);
    }

    public deleteQuoteSalesPerson(data): Observable<any> {
        const apiUrl = 'QuoteSalesPerson/DeleteQuoteSalesPerson'
        return this.httpBaseService.post(apiUrl, data);
    }

    public getSurveillanceScheduleList(): SurveillanceScheduleDto[] {
        return SURVEILLANCE_SCHEDULE_QUOTE;
    }

    public getQuoteMovementList(requestData): Observable<any> {
        const apiUrl = 'Quote/GetQuoteMovementList'
        return this.httpBaseService.post(apiUrl, requestData);
    }

    public createQuoteStandardGroup(payload): Observable<any> {
        const apiUrl = 'QuoteStandardGroup/CreateQuoteStandardGroup'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public updateQuoteStandardGroup(payload): Observable<any> {
        const apiUrl = 'QuoteStandardGroup/UpdateQuoteStandardGroup'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public deleteQuoteStandardGroup(payload): Observable<any> {
        const apiUrl = 'QuoteStandardGroup/DeleteQuoteStandardGroup'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public getQuoteStandardGroupsByQuoteId(id, userId): Observable<any> {
        const apiUrl = 'QuoteStandardGroup/GetQuoteStandardGroupsByQuoteId/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }
    public createQuoteStandardGroupLocation(payload): Observable<any> {
        const apiUrl = 'QuoteStandardGroupLocation/CreateQuoteStandardGroupLocation'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public updateQuoteStandardGroupLocation(payload): Observable<any> {
        const apiUrl = 'QuoteStandardGroupLocation/UpdateQuoteStandardGroupLocation'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public deleteQuoteStandardGroupLocation(payload): Observable<any> {
        const apiUrl = 'QuoteStandardGroupLocation/DeleteQuoteStandardGroupLocation'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public getQuoteStandardGroupLocationsByQuoteId(id, userId): Observable<any> {
        const apiUrl = 'QuoteStandardGroupLocation/GetQuoteStandardGroupLocationsByQuoteId/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }
    public getQuoteStandardGroupLocationById(id, userId): Observable<any> {
        const apiUrl = 'QuoteStandardGroupLocation/GetQuoteStandardGroupLocationById/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }
    public createOrUpdateQuoteLegalRequirements(payload): Observable<any> {
        const apiUrl = 'QuoteStandardGroupLocationsAndAssociatedStandardsLegalRequirement/CreateOrUpdateQuoteLegalRequirements'
        return this.httpBaseService.post(apiUrl, payload);
    }

    //Previous Cert
    public createQuoteTransferPrevCertDetail(payload): Observable<any> {
        const apiUrl = 'QuoteTransferPrevCertDetail/CreateQuoteTransferPrevCertDetail'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public updateQuoteTransferPrevCertDetail(payload): Observable<any> {
        const apiUrl = 'QuoteTransferPrevCertDetail/UpdateQuoteTransferPrevCertDetail'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public deleteQuoteTransferPrevCertDetail(payload): Observable<any> {
        const apiUrl = 'QuoteTransferPrevCertDetail/DeleteQuoteTransferPrevCertDetail'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public getQuoteTransferPrevCertDetailsByQuoteId(id, userId): Observable<any> {
        const apiUrl = 'QuoteTransferPrevCertDetail/GetQuoteTransferPrevCertDetailsByQuoteId/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }

    //Transfer Info Doc
    public createQuoteTransferRegisterTransferDocument(payload): Observable<any> {
        const apiUrl = 'QuoteTransferRegisterTransferDocument/CreateQuoteTransferRegisterTransferDocument'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public updateQuoteTransferRegisterTransferDocument(payload): Observable<any> {
        const apiUrl = 'QuoteTransferRegisterTransferDocument/UpdateQuoteTransferRegisterTransferDocument'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public deleteQuoteTransferRegisterTransferDocument(payload): Observable<any> {
        const apiUrl = 'QuoteTransferRegisterTransferDocument/DeleteQuoteTransferRegisterTransferDocument'
        return this.httpBaseService.post(apiUrl, payload);
    }
    public getQuoteTransferRegisterTransferDocumentsByQuoteId(id, userId): Observable<any> {
        const apiUrl = 'QuoteTransferRegisterTransferDocument/GetQuoteTransferRegisterTransferDocumentsByQuoteId/' + id + '/' + userId;
        return this.httpBaseService.get(apiUrl);
    }
}
