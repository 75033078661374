import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchDto } from '@app/shared/models/pagination/search-dto';
import { AppComponentBase } from '@shared/common/app-component-base';
import CustomStore from 'devextreme/data/custom_store';
import { NcrService } from '../shared/services/ncr.service';

@Component({
  selector: 'app-ncr-grid',
  templateUrl: './ncr-grid.component.html',
  styleUrls: ['./ncr-grid.component.css']
})
export class NcrGridComponent extends AppComponentBase implements OnInit {

  @Input('clientId') clientId;
  @Input('auditId') auditId;
  @Input('isNCRReadOnly') isNCRReadOnly: boolean = false;
  searchString = '';
  companyLocationId;
  isView: boolean = window.location.pathname.indexOf('/view') === -1 ? false : true;
  NCRList: any = [];

  constructor(private router: Router,
    private injector: Injector,
    private ncrService: NcrService) {
    super(injector);
  }

  ngOnInit(): void {
    this.getNcrList();
  }

  editNCR = (e) => {
    this.router.navigate([`app/ncr/${e.row.data.id}/edit`])
  }

  viewNCR(data: any) {
    if(this.isView){
      this.router.navigate([`app/ncr/view/${data}`]);
    }
    else{
      this.router.navigate([`app/ncr/edit/${data}`]);
    }
  }

  onToolbarPreparing(e) {
    e.toolbarOptions.items.push({
      location: 'after',
      widget: 'dxButton',
      visible: true,
      options: {
        icon: "fa fa-plus",
        text: 'Add',
        onClick: this.addNCR.bind(this)
      }
    });
  }

  getNcrList() {
    var self = this;
    this.NCRList = new CustomStore({
      key: "id",
      load: function (loadOptions: any) {
        const searchString = loadOptions.filter ? (loadOptions.filter[0].filterValue + '').trim() : ""
        let dto = {
          searchString: searchString,
          pageSize: loadOptions.take,
          pageNumber: (self.searchString != searchString) ? 1 : (loadOptions.skip / loadOptions.take) + 1,
          userId: self.appSession.user.id
        }
        let searchDto;
        if (self.clientId) {
          searchDto = {
            ...dto,
            clientId: self.clientId
          }
        }
        else {
          searchDto = dto;
        }

        if (self.auditId) {
          searchDto = {
            ...dto,
            auditId: self.auditId
          }
        }
        self.searchString = searchDto.searchString;
        self.spinnerService.show();
        return self.ncrService.getNcrList(searchDto)
          .toPromise()
          .then((data: any) => {
            self.spinnerService.hide();
            return {
              data: data.ncrList ? data.ncrList : [],
              totalCount: data.totalItemsCount
            };
          })
          .catch(error => {
            self.spinnerService.hide();
            abp.log.error(error);
            abp.notify.error("", "Failed to retrieve NCR list", { showCloseButton: true, timer: 10000 });
          });
      }
    });
  }

  addNCR() {
    // localStorage.setItem(AppConsts.localStorageKeys.scheduleDetailsFromPageKey, ManageAuditsFromPage.unScheduledAudits);
    if (this.clientId) {
      this.router.navigate([`app/ncr/add/client/${this.clientId}`]);
    }
    else if (this.auditId) {
      this.router.navigate([`app/ncr/add/${this.auditId}`]);
    }
    else {
      this.router.navigate([`app/ncr/add`]);
    }
  }

  onAuditLocationChange(data) {
    this.companyLocationId = data;
    this.getNcrList();
  }

  clearLocationSelection() {
    this.companyLocationId = null;
    this.getNcrList();
  }

}
